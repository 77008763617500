export default function useUtils() {
  function removeObjFromArray(obj, evaluate) {
    /* obj: Array. evaluate: Validation to find which object to delete.
    Example: this.utils.removeObjFromArray(this.arrayData, element => element.id === 5)
    */
    const position = obj.findIndex(evaluate)
    obj.splice(position, 1)
  }

  function compareObjs(obj1, obj2) {
    // Compare 2 objects to see if they are similar. Returns true or false.
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  return {
    removeObjFromArray,
    compareObjs,
  }
}
