import axios from '@axios'
import common from '@/libs/common'

export default function useradminService() {
  const { throwError } = common()
  const API_URL = process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  // General ---------------------------------------
  const fetchUsers = (data, callback) => {
    axios
      .get(`${API_URL}/admin/users`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchAllUsers = callback => {
    axios
      .get(`${API_URL}/admin/users/All`)
      .then(response => {
        callback(response.data.collection)
      })
      .catch(error => {
        throwError(error)
      })
  }
  const createUser = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/admin/users`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
  //  {
  //   axios
  //     .post(`${API_URL}/admin/users`, data)
  //     .then(response => {
  //       callback(response.data)
  //     })
  //     .catch(error => throwError(error))
  // }
  const fetchUser = (userName, callback) => {
    axios
      .get(`${API_URL}/admin/users/${userName}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateUser = (data, callback) => {
    axios
      .put(`${API_URL}/admin/users/${data.userName}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // Verificar
  const partialUpdateUser = (data, callback) => {
    axios
      .patch(`${API_URL}/admin/users/${data.userName}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteUser = (userName, callback) => {
    axios
      .delete(`${API_URL}/admin/users/${userName}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // Claims ----------------------------------------
  const fetchUserClaims = ({ userName }, data, callback) => {
    axios
      .get(`${API_URL}/admin/users/${userName}/claims`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createUserClaim = ({ userName }, data, callback) => {
    axios
      .post(`${API_URL}/admin/users/${userName}/claims`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteUserClaim = ({ userName, type }, data, callback) => {
    axios
      .delete(`${API_URL}/admin/users/${userName}/claims/${type}`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // Roles -----------------------------------------
  const fetchRoles = callback => {
    axios
      .get(`${API_URL}/roles`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchUserRoles = ({ userName }, callback) => {
    axios
      .get(`${API_URL}/admin/users/${userName}/roles`)
      .then(response => {
        if (response.data === '') callback([])
        else callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createUserRole = (data, callback) => {
    axios
      .post(`${API_URL}/admin/users/${data.userName}/roles`, { role: data.role })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteUserRole = ({ userName, roleName }, callback) => {
    axios
      .delete(`${API_URL}/admin/users/${userName}/roles/${roleName}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // Others ----------------------------------------
  const fetchUserLogins = ({ userName }, callback) => {
    axios
      .get(`${API_URL}/admin/users/${userName}/logins`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // Verificar
  const deleteUserLogins = ({ userName }, data, callback) => {
    axios
      .delete(`${API_URL}/admin/users/${userName}/logins`, { loginProvider: data.loginProvider, providerKey: data.providerKey })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateUserPassword = ({ userName }, data, callback) => {
    axios
      .put(`${API_URL}/admin/users/${userName}/password`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchUserLogs = ({ userName }, data, callback) => {
    axios
      .get(`${API_URL}/admin/users/${userName}/logs`, { limit: data.limit, offset: data.offset, search: data.search })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchUsers,
    fetchAllUsers,
    createUser,
    fetchUser,
    updateUser,
    partialUpdateUser,
    deleteUser,
    fetchUserClaims,
    createUserClaim,
    deleteUserClaim,
    fetchRoles,
    fetchUserRoles,
    createUserRole,
    deleteUserRole,
    fetchUserLogins,
    deleteUserLogins,
    updateUserPassword,
    fetchUserLogs,
  }
}
