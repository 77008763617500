import axios from '@axios'
import common from '@/libs/common'

export default function configService() {
  const { throwError } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchNotifications = (callback, nombreUsuario) => {
    axios
      .get(`${API_URL}/Configuraciones/UsuarioNotificaciones/${nombreUsuario}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateNotifications = (data, callback) => {
    axios
      .put(`${API_URL}/Configuraciones/UsuarioNotificaciones`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchNotifications,
    updateNotifications,
  }
}
