<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(changePassword)"
      >
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="New password"
              rules="required|password|min:8"
            >
              <b-form-group
                :label="$t('AppUser.edit.TabChangePassword.newPassword')"
                label-for="new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="new-password"
                    v-model="newPasswordValue"
                    name="new-password"
                    :placeholder="$t('AppUser.edit.TabChangePassword.newPassword')"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeNew"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer ml-2"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false : null">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('AppUser.edit.TabChangePassword.confirmNewPassword')"
              :rules="`required|confirmed:New password`"
            >
              <b-form-group
                :label="$t('AppUser.edit.TabChangePassword.confirmNewPassword')"
                label-for="user-edit-confirm-new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="user-edit-confirm-new-password"
                    v-model="confirmPassword"
                    name="confirm-password"
                    :placeholder="$t('AppUser.edit.TabChangePassword.newPassword')"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeConfirm"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false : null">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ confirm password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              enabled
            >
              {{ $t("AppUser.edit.TabChangePassword.btnSaveChanges") }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import useCommon from '@/libs/common'
import Ripple from 'vue-ripple-directive'
import useradminService from '@/services/useradmin.service'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newPasswordValue: '',
      confirmPassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeConfirm: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
    resetuserData() {
      this.newPasswordValue = ''
      this.confirmPassword = ''
    },
    changePassword() {
      const params = {
        password: this.newPasswordValue,
        confirmPassword: this.confirmPassword,
      }
      this.updateUserPassword({ userName: this.userData.userName }, params, () => {
        this.common.toastSuccess('Contraseña modificada')
        this.resetuserData()
      })
    },
  },
  setup() {
    const common = useCommon()
    const {
      updateUserPassword,
    } = useradminService()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      updateUserPassword,
      refFormObserver,
      getValidationState,
      common,
    }
  },
}
</script>
