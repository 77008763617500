<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('AppUser.list.errorFetchingUserData') }}
      </h4>
      <div class="alert-body">
        {{ $t('AppUser.list.noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          {{ $t('AppUser.list.userList') }}
        </b-link>
        {{ $t('AppUser.list.forOtherUsers') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppUser.edit.account') }}</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Password -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppUser.edit.changePassword') }}</span>
        </template>
        <user-edit-tab-change-password
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Notifications -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppUser.edit.notifications') }}</span>
        </template>
        <user-edit-tab-notification
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import useradminService from '@/services/useradmin.service'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabChangePassword from './UserEditTabChangePassword.vue'
import UserEditTabNotification from './UserEditTabNotification.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabChangePassword,
    UserEditTabNotification,
    // UserEditTabHumanResources,
  },
  setup() {
    const {
      fetchUser,
    } = useradminService()
    const userData = ref(null)

    fetchUser(router.currentRoute.params.userName, data => {
      userData.value = data
    })
    return {
      userData,
    }
  },
}
</script>

<style>

</style>
