<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="usrData.avatar"
            :text="avatarText(usrData.name)"
            :variant="`light-${resolveUserRoleVariant(usrData.role)}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ usrData.name }}
        </h4>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppUser.list.username')"
              label-for="user-name"
            >
              <b-form-input
                id="user-name"
                v-model="usrData.userName"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppUser.edit.fullName')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppUser.edit.fullName')"
                label-for="nombre-completo"
              >
                <b-form-input
                  id="nombre-completo"
                  v-model="usrData.name"
                  :state="getValidationState(validationContext)"
                  autocomplete="new-password"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppUser.edit.email')"
              rules="required|email"
            >
              <b-form-group
                :label="$t('AppUser.edit.email')"
                label-for="correo"
              >
                <b-form-input
                  id="correo"
                  v-model="usrData.email"
                  :state="getValidationState(validationContext)"
                  autocomplete="new-password"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone Number -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppUser.edit.phoneNumber')"
              rules="required|phoneNumber|min:10|max:18"
            >
              <b-form-group
                :label="$t('AppUser.edit.phoneNumber')"
                label-for="telefono"
              >
                <b-form-input
                  id="telefono"
                  v-model="usrData.phoneNumber"
                  :state="getValidationState(validationContext)"
                  autocomplete="new-password"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('AppUser.edit.saveChanges') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('AppUser.edit.reset') }}
        </b-button>
      </b-form>
    </validation-observer>

    <!-- PERMISSIONS -->
    <b-row>
      <b-col md="6">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('AppUser.edit.roles') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-group class="ml-1">
                <v-select
                  v-model="selectedRole"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="rolesData"
                  :reduce="option => option.name"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mr-1">
                <b-button
                  block
                  variant="success"
                  @click="add(selectedRole, 'userRole', userRolesData)"
                >
                  <feather-icon icon="ArrowDownCircleIcon" />
                  {{ $t('AppUser.edit.addRole') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="userRolesData"
            :fields="userRolesTableColumns"
          >
            <!-- Column: User -->
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="deleteClaimRole(data.item.name, 'userRole', userRolesData)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('AppUser.edit.customers') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-group class="ml-1">
                <v-select
                  v-model="selectedCustomer"
                  label="texto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customersData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mr-1">
                <b-button
                  block
                  variant="success"
                  @click="add(selectedCustomer, 'cliente_id', userClaimsCustomerData)"
                >
                  <feather-icon icon="ArrowDownCircleIcon" />
                  {{ $t('AppUser.edit.addCustomers') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="userClaimsCustomerData"
            :fields="userCustomerTableColumns"
          >
            <!-- Column: Customer -->
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                block
                @click="deleteClaimRole(data.item.cliente_id, 'cliente_id', userClaimsCustomerData)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('AppUser.edit.vendors') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-group class="ml-1">
                <v-select
                  v-model="selectedVendor"
                  label="texto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="vendorsData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mr-1">
                <b-button
                  block
                  variant="success"
                  @click="add(selectedVendor, 'proveedor_id', userClaimsVendorData)"
                >
                  <feather-icon icon="ArrowDownCircleIcon" />
                  {{ $t('AppUser.edit.addVendor') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="userClaimsVendorData"
            :fields="userVendorTableColumns"
          >
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="deleteClaimRole(data.item.proveedor_id, 'proveedor_id', userClaimsVendorData)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <!--Customs-->
      <b-col md="6">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('AppUser.edit.customs') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-group class="ml-1">
                <v-select
                  v-model="selectedCustom"
                  label="texto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customsData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mr-1">
                <b-button
                  block
                  variant="success"
                  @click="add(selectedCustom, 'aduana', userClaimsCustomData)"
                >
                  <feather-icon icon="ArrowDownCircleIcon" />
                  {{ $t('AppUser.edit.addCustom') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="userClaimsCustomData"
            :fields="userCustomTableColumns"
          >
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="deleteClaimRole(data.item.aduana, 'aduana', userClaimsCustomData)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <!--Patents-->
      <b-col md="6">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('AppUser.edit.patents') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-group class="ml-1">
                <v-select
                  v-model="selectedPatent"
                  label="texto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="patentsData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mr-1">
                <b-button
                  block
                  variant="success"
                  @click="add(selectedPatent, 'patente', userClaimsPatentData)"
                >
                  <feather-icon icon="ArrowDownCircleIcon" />
                  {{ $t('AppUser.edit.addPatent') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="userClaimsPatentData"
            :fields="userPatentTableColumns"
          >
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="deleteClaimRole(data.item.patente, 'patente', userClaimsPatentData)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import useradminService from '@/services/useradmin.service'
import optionService from '@/services/option.service'
import useCommon from '@/libs/common'
import useUtils from '@/libs/utils'
import vSelect from 'vue-select'
import router from '@/router'
import i18n from '@/libs/i18n'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const { toastInfo, toastSuccess } = useCommon()
    const { removeObjFromArray } = useUtils()
    const usrData = ref(JSON.parse(JSON.stringify(props.userData)))

    const resetUserData = () => {
      usrData.value = JSON.parse(JSON.stringify(props.userData))
    }

    const { resolveUserRoleVariant } = useUsersList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    // Services -------------------------------
    const {
      fetchCustomers,
      fetchVendors,
      fecthCustoms,
      fetchPatents,
    } = optionService()

    const {
      updateUser,
      fetchUserClaims,
      createUserClaim,
      deleteUserClaim,
      fetchRoles,
      fetchUserRoles,
      createUserRole,
      deleteUserRole,
    } = useradminService()

    const userRolesData = ref([])
    const customersData = ref([])
    const vendorsData = ref([])
    const customsData = ref([])
    const patentsData = ref([])
    const userClaimsCustomerData = ref([])
    const userClaimsVendorData = ref([])
    const userClaimsCustomData = ref([])
    const userClaimsPatentData = ref([])
    const rolesData = ref([])
    const selectedRole = ref(null)
    const selectedCustomer = ref(null)
    const selectedVendor = ref(null)
    const selectedPatent = ref(0)
    const selectedCustom = ref(0)
    const claimsData = ref([])

    const userRolesTableColumns = [
      { key: 'name', label: i18n.t('AppUser.list.role'), sortable: true },
      { key: 'delete', label: i18n.t('Lists.Delete') },
    ]
    const userCustomerTableColumns = [
      { key: 'texto', label: i18n.t('AppCustomer.Customer'), sortable: true },
      { key: 'delete', label: i18n.t('Lists.Delete') },
    ]
    const userVendorTableColumns = [
      { key: 'texto', label: i18n.t('AppVendor.vendor'), sortable: true },
      { key: 'delete', label: i18n.t('Lists.Delete') },
    ]
    const userCustomTableColumns = [
      { key: 'texto', label: i18n.t('AppDigitalRecord.list.custom'), sortable: true },
      { key: 'delete', label: i18n.t('Lists.Delete') },
    ]
    const userPatentTableColumns = [
      { key: 'texto', label: i18n.t('confront.warehouse.list.patent'), sortable: true },
      { key: 'delete', label: i18n.t('Lists.Delete') },
    ]
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    fetchRoles(data => {
      rolesData.value = data
    })

    fetchUserClaims({ userName: usrData.value.userName }, {}, data => {
      claimsData.value = data
      if (data) {
        fetchCustomers(customers => {
          customersData.value = customers
          if (customers) {
            const customerClaims = claimsData.value.filter(claim => claim.type === 'cliente_id')
            const result = customersData.value.filter(customerData => customerClaims.some(claim => customerData.id.toString() === claim.value))
            userClaimsCustomerData.value = result.map(({ texto, id }) => ({ texto, cliente_id: id }))
          }
        })

        fecthCustoms(customs => {
          customsData.value = customs
          if (customs) {
            const customClaims = claimsData.value.filter(claim => claim.type === 'aduana')
            const result = customsData.value.filter(customData => customClaims.some(claim => customData.id.toString() === claim.value))
            userClaimsCustomData.value = result.map(({ texto, id }) => ({ texto, aduana: id }))
          }
        })

        fetchVendors(clients => {
          vendorsData.value = clients
          if (clients) {
            const customerClaims = claimsData.value.filter(claim => claim.type === 'proveedor_id')
            const result = vendorsData.value.filter(vendorData => customerClaims.some(claim => vendorData.id.toString() === claim.value))
            userClaimsVendorData.value = result.map(({ texto, id }) => ({ texto, proveedor_id: id }))
          }
        })

        fetchPatents(patents => {
          patentsData.value = patents
          if (patents) {
            const patentClaims = claimsData.value.filter(claim => claim.type === 'patente')
            const result = patentsData.value.filter(patentData => patentClaims.some(claim => patentData.id.toString() === claim.value))
            userClaimsPatentData.value = result.map(({ texto, id }) => ({ texto, patente: id }))
          }
        })
      }
    })

    fetchUserRoles({ userName: usrData.value.userName }, data => {
      userRolesData.value = data
    })

    const add = (value, type, userClaimsTypeData) => {
      let isUnique = false
      let messageAsiggned = i18n.t('AppUser.info.customerAssigned')
      let messageAdded = i18n.t('AppUser.info.custommerAdded')
      let messageSelect = i18n.t('AppUser.info.selectRole')
      let data = {}
      let texto = ''
      let id = 0
      const { userName } = usrData.value
      if (value !== null) {
        if (value.id !== undefined) {
          id = value.id
          texto = value.texto
          data = { type, value: id }
        }
      }

      if (type === 'cliente_id') {
        messageAdded = i18n.t('AppUser.info.custommerAdded')
        messageAsiggned = i18n.t('AppUser.info.customerAssigned')
        messageSelect = i18n.t('AppUser.info.selectCustomer')
      } else if (type === 'proveedor_id') {
        messageAdded = i18n.t('AppUser.info.vendorAdded')
        messageAsiggned = i18n.t('AppUser.info.vendorAssigned')
        messageSelect = i18n.t('AppUser.info.selectVendor')
      } else if (type === 'aduana') {
        messageAdded = i18n.t('AppUser.info.customAddeed')
        messageAsiggned = i18n.t('AppUser.info.customAssigned')
        messageSelect = i18n.t('AppUser.info.selectCustom')
      } else if (type === 'patente') {
        messageAdded = i18n.t('AppUser.info.patentAdded')
        messageAsiggned = i18n.t('AppUser.info.patentAssigned')
        messageSelect = i18n.t('AppUser.info.selectPatent')
      } else if (type === 'userRole') {
        messageAdded = i18n.t('AppUser.edit.addedRol')
        messageAsiggned = i18n.t('AppUser.info.roleAssigned')
        messageSelect = i18n.t('AppUser.info.selectRole')
      }
      if (value !== null && value !== 0 && Object.keys(value).length !== 0) {
        switch (type) {
          case 'userRole':
            isUnique = !userRolesData.value.find(role => role.name === value)
            if (isUnique) {
              createUserRole({ userName, role: value }, () => {
                toastSuccess(messageAdded)
                claimsData.value.push({ type: 'role', value })
                userRolesData.value.push({ name: value })
                selectedRole.value = null
              })
            } else {
              toastInfo(messageAsiggned)
            }
            break

          case 'cliente_id':
          case 'proveedor_id':
          case 'aduana':
          case 'patente':
            isUnique = !userClaimsTypeData.find(element => element.texto === texto)
            if (isUnique) {
              createUserClaim({ userName }, data, () => {
                claimsData.value.push(data)
                toastSuccess(messageAdded)
                const dataArray = { texto }
                dataArray[type] = id
                userClaimsTypeData.push(dataArray)

                if (type === 'cliente_id') {
                  selectedCustomer.value = null
                } else if (type === 'proveedor_id') {
                  selectedVendor.value = null
                } else if (type === 'aduana') {
                  selectedCustom.value = null
                } else if (type === 'patente') {
                  selectedPatent.value = null
                }
              })
            } else {
              toastInfo(messageAsiggned)
            }
            break

          default:
            break
        }
      } else {
        toastInfo(messageSelect)
      }
    }

    const deleteClaimRole = (value, type, userClaimsTypeData) => {
      const { userName } = usrData.value
      let messageDeleted = i18n.t('AppUser.info.roleDeleted')

      if (type === 'cliente_id') {
        messageDeleted = i18n.t('AppUser.info.customerDeleted')
      } else if (type === 'proveedor_id') {
        messageDeleted = i18n.t('AppUser.info.vendorDeleted')
      } else if (type === 'aduana') {
        messageDeleted = i18n.t('AppUser.info.customDeleted')
      } else if (type === 'patente') {
        messageDeleted = i18n.t('AppUser.info.petentDeleted')
      } else if (type === 'userRole') {
        messageDeleted = i18n.t('AppUser.info.roleDeleted')
      }

      switch (type) {
        case 'userRole':
          deleteUserRole({ userName, roleName: value }, () => {
            toastSuccess(messageDeleted)
            removeObjFromArray(userRolesData.value, role => role.name === value)
          })
          break
        case 'cliente_id':
        case 'proveedor_id':
        case 'aduana':
        case 'patente':
          deleteUserClaim({ userName, type }, { value }, () => {
            toastSuccess(messageDeleted)
            removeObjFromArray(claimsData.value, claim => claim.type === type && claim.value === value)
            removeObjFromArray(userClaimsTypeData, claim => claim[type] === value)
          })
          break
        default:
          break
      }
    }

    const onSubmit = () => {
      updateUser(usrData.value, () => {
        router.push({ name: 'apps-users-list' })
      })
    }

    return {
      onSubmit,

      // Validation
      refFormObserver,
      getValidationState,
      resetForm,

      resolveUserRoleVariant,
      avatarText,

      rolesData,
      customsData,
      customersData,
      patentsData,
      vendorsData,

      selectedRole,
      selectedCustomer,
      selectedVendor,
      selectedPatent,
      selectedCustom,

      userRolesData,
      claimsData,

      userRolesTableColumns,
      userCustomerTableColumns,
      userVendorTableColumns,
      userCustomTableColumns,
      userPatentTableColumns,
      userClaimsCustomerData,
      userClaimsVendorData,
      userClaimsCustomData,
      userClaimsPatentData,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      usrData,
      // Methods
      add,
      deleteClaimRole,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
