<template>
  <div>
    <b-table
      :fields="fields"
      :items="notificationsData"
      class="table-borderless position-relative"
      responsive
    >
      <template #cell(portal)="data">
        <b-form-checkbox
          v-model="data.item.portal"
          @change="onUpdateNotificacion(data.item)"
        />
      </template>
      <template #cell(sms)="data">
        <b-form-checkbox
          v-model="data.item.sms"
          @change="onUpdateNotificacion(data.item)"
        />
      </template>
      <template #cell(whatsapp)="data">
        <b-form-checkbox
          v-model="data.item.whatsapp"
          @change="onUpdateNotificacion(data.item)"
        />
      </template>
      <template #cell(correo)="data">
        <b-form-checkbox
          v-model="data.item.correo"
          @change="onUpdateNotificacion(data.item)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import configService from '@/services/config.service'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    /* Services */
    const {
      fetchNotifications,
      updateNotifications,
    } = configService()
    /* Data */
    const usrData = ref(JSON.parse(JSON.stringify(props.userData)))
    const notificationsData = ref([])
    const fields = [
      {
        label: i18n.t('AppUser.TabNotification.notificationLabel'),
        key: 'notificacionOrigen.nombre',
      },
      {
        label: i18n.t('AppUser.TabNotification.portal'),
        key: 'portal',
      },
      {
        label: i18n.t('AppUser.TabNotification.sms'),
        key: 'sms',
      },
      {
        label: i18n.t('AppUser.TabNotification.whatsApp'),
        key: 'whatsapp',
      },
      {
        label: i18n.t('AppUser.TabNotification.email'),
        key: 'correo',
      },
    ]

    const refetchNotifications = () => {
      fetchNotifications(data => {
        notificationsData.value = data
      }, usrData.value.userName)
    }

    refetchNotifications()
    /* Events */
    const onUpdateNotificacion = item => {
      updateNotifications(item, () => {
        refetchNotifications()
      })
    }

    return {
      // Data
      notificationsData,
      fields,
      // Events
      onUpdateNotificacion,
    }
  },
}
</script>

<style>
  .table-borderless > tbody > tr > td,
  .table-borderless > tbody > tr > th,
  .table-borderless > tfoot > tr > td,
  .table-borderless > tfoot > tr > th,
  .table-borderless > thead > tr > td,
  .table-borderless > thead > tr > th {
      border: none;
  }
</style>
